<template>
    <div class="chat__body">
        <div class="chat__scroller">
            <div class="chat__messages">
                <MessageBox
                    :message="m"
                    v-for="(m, i) in list"
                    :key="'msg_' + i"
                    @button="button"
                    @command="command"
                />
            </div>
            <div class="chat__scroller-end"></div>
        </div>
    </div>
</template>

<script>
import MessageBox from "./MessageBox.vue";


export default {
    name: "ChatBody",

    props: {
        list: {
            type: Array,
            default: () => [],
        },
    },

    components: {
        MessageBox,
    },

    computed: {
        count() {
            return this.list.length;
        },
    },

    methods: {

        command(command, text) {
            this.$emit('command', command, text);
        },
        button(button) {
            this.$emit('button', button);
        },

    },

    emits: ['command', 'button'],

    watch: {
        count() {
            // TODO only on add message !!
            setTimeout(() => {
                document.querySelector(".chat__scroller-end").scrollIntoView({ block: "center", behavior: "smooth" });
            });
        },
    },

    // mounted() {
    //     console.log('mounted ' + this.$options.name);
    // },

};
</script>

<style scoped>
.chat__body {
    margin: 0;
    padding: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    max-height: 100%;
    width: calc(100% - 40px);
    flex: 1 1 auto;
}
.chat__scroller,
.chat__messages {
    margin: 0;
    padding: 0;
    list-style: none;
    min-height: 100%;
    display: flex;
    flex-direct: column;
    justify-content: flex-end;
}
.chat__messages {
    perspective: 800px;
}

/* Small screens */
/* @media all and (max-width: 767px) {} */
/* Medium and large screens */
/* @media all and (min-width: 768px) {}
@keyframes show-chat-even {
    0% {
        margin-left: -480px;
    }
    100% {
        margin-left: 0;
    }
}
@-moz-keyframes show-chat-even {
    0% {
        margin-left: -480px;
    }
    100% {
        margin-left: 0;
    }
}
@-webkit-keyframes show-chat-even {
    0% {
        margin-left: -480px;
    }
    100% {
        margin-left: 0;
    }
} */
</style>