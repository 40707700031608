<template>
    <div class="message" :class="{'message_revert': !user.is_bot, 'message_hide': message.hide}">
        <div class="message__content">
            <div class="message__user">
                <i :class="user.icon"></i>
            </div>
            <div class="message__text" :class="[{'message__text_with-image': message.src}, message.class]" @click="msgClick">
                <img v-if="message.src" :src="message.src"/>
                <template v-if="message.text || message.command">
                    <a class="message__text_command link" v-if="message.command" @click="cmdClick">/{{message.command}}</a>

                    <span class="message__text_html" v-if="message.html" v-html="message.text"></span>
                    <span class="message__text_text" v-else>{{ message.text }}</span>
                </template>
            </div>
        </div>        
        <div class="message__buttons" v-if="showButtons">
            <div class="message__buttons-row" v-for="(btnsRow, rowIx) in buttons" :key="'btnsRow_'+rowIx">
                <template v-for="(btn, btnIx) in btnsRow" :key="'btn_'+btnIx">
                    <div v-if="btn.html" class="w-100 btn-html">
                        <div v-if="btn.html" v-html="btn.html" class="btn-html"></div>
                        <button class="button w-100" @click="btnClick(btn)" v-show="!btn.hide">{{ btn.text }}</button>
                    </div>
                    <button class="button" v-else @click="btnClick(btn)" v-show="!btn.hide">{{ btn.text }}</button>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MessageBox',
  
    props: {
        message: {
            type: Object,
            default: null
        },
    },

    computed: {
        buttons() {
            let btns = [];
            if(this.message && this.message.buttons && this.message.buttons.length) {
                btns = (Array.isArray(this.message.buttons[0])) ? this.message.buttons : [this.message.buttons];
            }
            return btns; 
        },
        user() {
            return (this.message && this.message.from) ? this.message.from : {};
        },
        showButtons() {
            return (this.user.is_bot && this.buttons.length);
        }
    },

    methods: {
        msgClick() {
            return this.message.link ? window.open(this.message.link, "_target") : null;
        },
        cmdClick() {
            return this.$emit('command', this.message.command, this.message.text);
        },
        btnClick(btn) {
            return this.$emit('button', btn);
        },
    },

    emits: ['command', 'button'],

    mounted(){
        // if(this.message.hide) console.info('%cMessageBox MOUNTED [hide]', 'color: blue;', this.message);// TODO @DEV
    }
    
}
</script>

<style scoped>
.message { 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.message:not(:first-of-type) {
    margin-top: 24px;
}

.message:not(.message_revert) .message__user { 
    order: 0;
    margin-right: 24px;
}
.message.message_revert .message__user { 
    order: 2;
    margin-left: 24px;
}
.message_hide {
    opacity: 0.2 !important;
    display: none;
}

.message__content { 
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.message__user { 
    width: 48px;
    flex: 0;
    display: flex;
    align-items: flex-end;
}
.message__user [class^="icon-"], 
.message__user [class*=" icon-"] { 
    width: 48px;
    height: 48px;
    background-color: rgb(0, 0, 0, 0.4);
    border-radius: 50%;
}
.message__user [class^="icon-"]:before, 
.message__user [class*=" icon-"]:before { 
    width: 80%;
    height: 80%;
}
.message__text { 
    position: relative;
    /* display: inline-block; */
    flex: auto;
    padding: 16px 20px;
    font: 16px/20px Arial, sans-serif;
    border-radius: 8px;
    background-color: rgba(25, 147, 147, 0.2);
    display: flex;
}

.message__text_with-image {
    flex-direction: column;
}
.message__text img {
   max-width: 100%;
}

.message__text::after {
    position: absolute;
    bottom: 10px;
    content: "";
    width: 0;
    height: 0;
    border-bottom: 15px solid rgba(25, 147, 147, 0.2);
}
/* .message__text_html + .message__text_text::before {
    content: "\00A0";
} */
.message__text_command + .message__text_text::before {
    content: "\00A0";
}
.message:not(.message_revert) .message__text::after {
    border-left: 15px solid transparent;
    left: -15px;
}
.message.message_revert .message__text::after {
    border-right: 15px solid transparent;
    right: -15px;
}

.message:not(.message_revert) .message__buttons { 
    margin-left: 72px;
}
.message.message_revert .message__buttons { 
    margin-right: 72px;
}
.message__buttons { 
    margin-top: 16px;
    display: flex;
    flex-direction: column;
}
.message__buttons-row { 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.message__buttons-row:not(:first-child) { 
    margin-top: 6px;
}
.message__buttons-row .button { 
    /* display: inline-flex; */
    flex: 1;
}
.message__buttons-row .button:not(:first-child) { 
    margin-left: 6px;
}


.message {
  animation: msg 0.4s;
  transform: rotateX(0deg);
  opacity: 1;
}
@keyframes msg {
  from {
    transform: rotateX(-90deg);
    opacity: 0;
  }
  to {
    transform: rotateX(0deg);
    opacity: 1;
  }
}

.btn-html {
    padding: 10px 0;
}
.message__buttons-row .btn-html .button {
    margin-left: 0;
}
</style>