<template>
    <div class="chat__footer chat__block" :class="{ 'flex-center': !count }">
        <div v-if="!count" class="link w-100" style="text-align: center;cursor: pointer;" @click="start">Начать</div>
        <template v-else>
            <div class="chat__form-wrapper w-100">
                <form class="input-control w-100" ref="form" @submit.prevent="submit" :disabled="!text">
                    <i class="icon-smile"></i>
                    <input class="input" v-model="text" placeholder="Сообщение..." />
                    <i class="icon-attach"></i>
                    <button class="icon-send" type="submit" :disabled="!text"></button>
                </form>
            </div>

            <slot></slot>

            <template v-for="btn in keyboards" :key="btn.name">
                <!-- <div class="chat__keyboard-button" v-if="opens[btn.name]"> -->
                <div class="chat__keyboard-button">
                    <button class="button w-100" @click="clickBtn(btn)">{{ btn.text }}</button>
                </div>
            </template>
            
        </template>
    </div>
</template>

<script>
export default {
    name: 'ChatFooter',

    props: ['count', 'keyboards'],

    data() {
        return {
            text: ''
        }
    },

    emits: ['start', 'submit'],

    methods: {
        start(e) {
            e.preventDefault();
            this.$emit('start');
        },

        parse() {

            let msg = { text: this.text.trim() };

            if (msg.text[0] === '/') {
                let words = msg.text.substr(1).split(" ");

                msg.command = words.shift().toLowerCase() || null, 
                msg.text =  words.join(" "),// TODO надо вырезать все "плохие" теги
                msg.html = /<\/?[a-z][\s\S]*>/i.test(msg.text);
            }

            return msg;
        },

        clickBtn(btn) {
            if(btn.msg) this.$emit('submit', btn.msg);
            else console.error('clickBtn', btn);
        },

        submit(e) {
            e.preventDefault();
            
            if(this.text.trim()) {
                this.$emit('submit', this.parse());
                this.text = '';
            }
        }
    }


}
</script>

<style scoped>


.chat__footer {
    /* height: 50px; */
    /* background-color: rgb(32, 32, 32); */
    display: flex;
    flex-direct: column;
    justify-content: space-between;
}
.chat__footer .chat__form-wrapper {
    min-height: 50px;
    /* background-color: rgb(32, 32, 32); */
    display: flex;
    flex-direct: row;
    justify-content: space-between;
}
.input-control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 20px);
    margin: 0 auto;
}
.input-control > * {
    flex-grow: 1;
}
.input-control > * ~ * {
    margin-left: 8px;
}
.input-control > .input {
    flex-grow: 10;
}
.input-control > [class^="icon-"],
.input-control > [class*=" icon-"] {
    max-width: 32px;
    height: 32px;
    border-radius: 50%;
}
.input-control > [class^="icon-"]:hover,
.input-control > [class*=" icon-"]:hover {
    cursor: pointer;
    background-color: rgb(32, 32, 32);
}

.chat__keyboard-button {
    animation: toup 0.4s;
    padding: 20px;
    /* width: calc(100% - 20px); */
    margin-bottom: 0;
    /* transform: rotateX(0deg); */
    opacity: 1;
}
@keyframes toup {
    from {
        /* margin-bottom: -100%; */
        margin-bottom: -60px;
        opacity: 0;
    }
    to {
        margin-bottom: 0;
        opacity: 1;
    }
}
</style>